import { Controller } from "@hotwired/stimulus";
import { squareMeterRound } from "../utilities/square_meter_calculations";

export default class extends Controller {
  static values = {
    minAmount: Number,
    commission: Number,
    squareMeterPrice: Number,
  };

  static targets = [
    "confirmButton",
    "recurringMessage",
    "squareMeters",
    "totalPrice",
    "totalPriceOverview",
    "recurringEnabled",
    "recurringFrequency",
    "frequencyText",
  ];

  connect() {
    this.calculate();
  }

  calculateSquareMeters() {
    if (!this.hasTotalPriceTarget) return;

    const squareMeters = squareMeterRound(
      (this.totalPriceTarget.value * (1 - this.commissionValue)) /
      this.squareMeterPriceValue
    );

    this.squareMetersTargets.forEach((el) => {
      el.innerHTML = squareMeters;
    });
  }

  toggleButtonState() {
    if (!this.hasConfirmButtonTarget) return;

    if (this.totalPriceTarget.value >= this.minAmountValue) {
      this.confirmButtonTarget.removeAttribute("disabled");
    } else {
      this.confirmButtonTarget.setAttribute("disabled", "disabled");
    }
  }

  setRecurringText() {
    if (!this.hasRecurringEnabledTarget) return;

    if (this.recurringEnabledTarget.checked) {
      this.recurringMessageTarget.style.display = "inline";
      this.frequencyTextTarget.innerHTML =
        this.recurringFrequencyTarget.options[
          this.recurringFrequencyTarget.selectedIndex
        ].text.toLowerCase();
    } else {
      this.recurringMessageTarget.style.display = "none";
      this.frequencyTextTarget.innerHTML = "";
    }
  }

  setTotalPrice() {
    if (!this.hasTotalPriceOverviewTarget) return;

    this.totalPriceOverviewTargets.forEach((el) => {
      el.innerHTML = this.totalPriceTarget.value;
    });
  }

  calculate() {
    this.calculateSquareMeters();
    this.toggleButtonState();
    this.setRecurringText();
    this.setTotalPrice();
  }
}
